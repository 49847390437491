<template>
  <v-form
    @submit.prevent="handleSubmitForm"
    class="contact-form"
    autocomplete="disabled"
  >
    <v-card :loading="form_loading">
      <v-card-title>
        {{ __("Novo endereço", "address") }}
        <v-spacer></v-spacer>

        <v-btn @click="handleCancel" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <ValidationObserver ref="address_form" v-slot="{ invalid }">
          <ValidationProvider
            name="código postal"
            rules="max:15"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="__('CEP', 'address')"
              name="postal_code"
              type="text"
              :hint="
                capitalize($tc('model.interface_not_necessary_field_hint', 1))
              "
              persistent-hint
              :value="postal_code"
              @change="postal_code = $event"
              :counter="15"
              :error-messages="errors"
              :disabled="form_loading || form_submitting"
              autofocus
              autocomplete="disabled"
            />
          </ValidationProvider>
          <ValidationProvider
            name="address.district"
            rules="required"
            v-slot="{ errors }"
          >
            <select-country-area
              v-model="country_area"
              :label="__('Estado', 'address')"
              :error-messages="errors"
              name="country_area"
              clearable
              :disabled="form_loading || form_submitting"
              autocomplete="disabled"
            ></select-country-area>
          </ValidationProvider>
          <ValidationProvider
            name="address.country"
            rules="required"
            v-slot="{ errors }"
          >
            <select-city
              v-model="city"
              :country_area="country_area"
              :label="__('Cidade', 'address')"
              :error-messages="errors"
              name="city"
              clearable
              :disabled="form_loading || form_submitting"
              autocomplete="disabled"
            ></select-city>
          </ValidationProvider>
          <ValidationProvider name="address.neighborhood" v-slot="{ errors }">
            <select-city-area
              v-model="city_area"
              :city="city"
              :label="__('Bairro', 'address')"
              :error-messages="errors"
              name="city_area"
              clearable
              return-object
              :disabled="form_loading || form_submitting"
              autocomplete="disabled"
            ></select-city-area>
          </ValidationProvider>
          <ValidationProvider
            name="address.street"
            rules="required|max:100"
            v-slot="{ errors }"
          >
            <v-combobox
              v-model="form.street"
              :items="streets"
              :label="__('Rua', 'address')"
              name="street"
              :error-messages="errors"
              :disabled="form_loading || form_submitting"
              autocomplete="disabled"
            ></v-combobox>
          </ValidationProvider>
          <ValidationProvider
            name="address.number"
            rules="max:45"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="__('Número', 'address')"
              name="number"
              type="text"
              :counter="45"
              v-model="form.number"
              :error-messages="errors"
              :disabled="form_loading || form_submitting"
            />
          </ValidationProvider>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="handleCancel"
          text
          :disabled="form_loading || form_submitting"
          >{{ capitalize($tc("model.interface_cancel")) }}</v-btn
        >
        <v-btn type="submit" color="primary" :loading="form_submitting">{{
          capitalize($tc("model.interface_create_button"))
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import SelectCountryArea from "../locals/SelectCountryArea";
import SelectCity from "../locals/SelectCity";
import SelectCityArea from "../locals/SelectCityArea";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import { autocompleteZipCode } from "../../services/locals";
import i18n from "@/mixins/i18n";

export default {
  name: "address-form",
  components: { SelectCountryArea, SelectCity, SelectCityArea },
  mixins: [i18n, ToastsMixin],
  props: {
    contact_id: Number,
    init: Object,
    form_submitting: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    form: {},
    postal_code: "",
    country_area: null,
    city: null,
    city_area: null,
    streets: [],
    form_loading: false,
  }),
  methods: {
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    async getPostalAddresses(postal_code) {
      if (!postal_code) return;

      this.country_area = null;
      this.city = null;
      this.streets = [];
      this.form.street = "";

      this.form_loading = true;
      try {
        const address = await autocompleteZipCode("pt", postal_code);

        if (address.country_area) {
          this.country_area = {
            id: address.country_area.country_area_id,
            name: address.country_area.country_area,
          };
        }

        if (address.city) this.city = address.city;

        if (address.city_area) {
          this.city_area = {
            id: address.city_area.city_area_id,
            name: address.city_area.city_area,
          };
        }

        this.streets = address.streets;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.__("Falha ao consultar", "address"),
          color: "red",
        });
      } finally {
        this.form_loading = false;
      }
    },
    async handleSubmitForm() {
      const validation = await this.$refs.address_form.validate();

      if (validation) {
        this.$emit("submit", {
          ...this.form,
          postal_code: this.postal_code,
          city: this.city,
          country_area: this.country_area,
          city_area: this.city_area,
        });
        this.resetForm();
      } else {
        const errors = [];
        this.$refs.address_form.$children.forEach((provider) => {
          if (provider.errors.length) {
            errors.push(`${provider.errors.join(", ")}`);
          }
        });

        if (errors.length) {
          this.toastError(errors.join(" | "));
        } else {
          this.toastError("Erro desconhecido no formulário.");
        }
      }
    },
    resetForm() {
      this.postal_code = "";
      this.country_area = null;
      this.city = null;
      this.city_area = null;
      this.form = {
        country_area: null,
      };

      if (this.$refs.address_form) this.$refs.address_form.reset();
    },
    handleCancel() {
      this.resetForm();
      this.$emit("cancel");
    },
  },
  created() {
    if (this.init) {
      this.form = { ...this.init };
      this.country_area = this.init.country_area;
      this.city = this.init.city;
      this.city_area = this.init.city_area;
      this.postal_code = this.init.postal_code;
    }
  },
  watch: {
    init: function (value) {
      if (value) {
        this.form = { ...value };
        this.country_area = { ...value.country_area };
        this.city = { ...value.city };
        this.city_area = { ...value.city_area };
        this.postal_code = this.init.postal_code;
      }
    },
    postal_code: function (value) {
      if (!this.country_area && !this.city && value)
        this.getPostalAddresses(value);
    },
  },
};
</script>
