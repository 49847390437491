<template>
  <v-autocomplete
    :items="city_areas"
    item-text="name"
    @change="handleSelect"
    :loading="loading"
    :value="city_area"
    v-bind="$props"
    :disabled="!city"
    :hint="
      !city ? __('Primeiro escolha uma cidade', 'address') : ''
    "
    persistent-hint
    return-object
  ></v-autocomplete>
</template>

<script>
import { getCityAreas } from "../../services/locals";
import i18n from '@/mixins/i18n';

export default {
  name: "select-city-area",
  mixins: [i18n],
  props: {
    city_area: Object,
    city: Object,
    label: String,
    clearable: Boolean,
    "error-messages": Array,
    name: String,
    autocomplete: String,
  },
  model: {
    prop: "city_area",
    event: "change",
  },
  data: () => ({
    city_areas: [],
    loading: false,
  }),
  methods: {
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    async getCityAreas(city) {
      if (!city) {
        return;
      }
      this.loading = true;
      this.city_areas = await getCityAreas(city.id);
      this.loading = false;
    },
    handleSelect(value) {
      this.$emit("change", value);
    },
  },
  created() {
    this.getCityAreas(this.city);
  },
  watch: {
    city: function (value) {
      this.getCityAreas(value);

      if (!value) {
        this.$emit("change", null);
      }
    },
  },
};
</script>