<template>
  <v-autocomplete
    :items="cities"
    item-text="name"
    @change="handleSelect"
    :loading="loading"
    :value="city"
    v-bind="$props"
    :disabled="!country_area"
    :hint="
      !country_area
        ? __('Primeiro escolha um estado', 'address')
        : ''
    "
    persistent-hint
    return-object
  ></v-autocomplete>
</template>

<script>
import { getCities } from "../../services/locals";
import i18n from '@/mixins/i18n';

export default {
  name: "select-city",
  mixins: [i18n],
  props: {
    city: Object,
    country_area: Object,
    label: String,
    clearable: Boolean,
    "error-messages": Array,
    name: String,
    autocomplete: String,
  },
  model: {
    prop: "city",
    event: "change",
  },
  data: () => ({
    cities: [],
    loading: false,
  }),
  methods: {
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    async getCities(country_area) {
      if (!country_area) {
        return;
      }
      this.loading = true;
      this.cities = await getCities(country_area.id);
      this.loading = false;
    },
    handleSelect(value) {
      this.$emit("change", value);
    },
  },
  created() {
    this.getCities(this.country_area);
  },
  watch: {
    country_area: function (value) {
      this.getCities(value);

      if (!value) {
        this.$emit("change", null);
      }
    },
  },
};
</script>