<template>
  <v-autocomplete
    :items="country_areas"
    item-text="name"
    item-value="id"
    @change="handleSelect"
    :loading="loading"
    :value="country_area"
    v-bind="$props"
    return-object
    :disabled="loading"
  ></v-autocomplete>
</template>

<script>
import { getCountryAreas } from "../../services/locals";

export default {
  name: "select-country-area",
  props: {
    country_area: Object,
    label: String,
    clearable: Boolean,
    "error-messages": Array,
    name: String,
    autofocus: Boolean,
    autocomplete: String
  },
  model: {
    prop: "country_area",
    event: "change"
  },
  data: () => ({
    country_areas: [],
    loading: false
  }),
  methods: {
    async getCountryAreas() {
      this.loading = true;
      this.country_areas = await getCountryAreas('pt');
      this.loading = false;
    },
    handleSelect(value) {
      this.$emit("change", value);
    }
  },
  created() {
    this.getCountryAreas();
  },
};
</script>